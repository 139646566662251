import '@fontsource/manrope/700.css'
import {
    Box,
    Heading,
    Container,
    Flex,
    Text,
    Button,
    Stack,
    Image
} from '@chakra-ui/react';
import logo from "./favicon.ico"
export default function CallToActionWithAnnotation() {
    return (
        <>


            <Container maxW={'3xl'}>

                <Stack
                    as={Box}
                    textAlign={'center'}
                    spacing={{ base: 8, md: 14 }}
                    py={{ base: 20, md: 36 }}>
                    <Flex flexDir={"row"} alignItems={"center"} justifyContent={"row"}>
                        <Heading fontFamily={"Manrope"}> > sqlnext.io</Heading>

                    </Flex>
                    <Heading
                        fontWeight={600}
                        fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                        lineHeight={'110%'}>
                        Build data apps faster across all clouds <br />
                        <Text as={'span'} color={'green.400'}>
                            with just SQL
                        </Text>
                    </Heading>
                    <Text align="justify" color={'gray.500'}>
                        sqlnext is all about building data apps faster.
                        We have built the modern SQL that brings together data integration, data flows, data discoverability and data permissions across hybrid cloud environments (warehouses, lakes, ML and chains).
                        Use our SDKs to  deploy, embed, or share  data applications in your preferred channels/front-end applications, within minutes.

                    </Text>
                    <Stack
                        direction={'column'}
                        spacing={3}
                        align={'center'}
                        alignSelf={'center'}
                        position={'relative'}>
                        <Button
                            colorScheme={'green'}
                            bg={'green.400'}
                            rounded={'full'}
                            transition={""}
                            px={6}
                            transition={{
                                duration: 3,
                                ease: "easeInOut"
                        }}
                            _hover={{
                                    boxShadow:'lg',
                                border:'1px solid white',
                                color:'white',
                                    bg: 'linear-gradient(to right, #a8ff78, #6be585)',

                            }}>
                           Coming Soon...
                        </Button>


                    </Stack>
                </Stack>
            </Container>
        </>
    );
}


