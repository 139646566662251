import '@fontsource/raleway/400.css'
import '@fontsource/open-sans/700.css'
import logo from "./favicon.ico";
import {
    ChakraProvider,
    Container,
    Flex,
    Icon,
    createIcon,
    Image
} from '@chakra-ui/react'

import theme from './themes';
import CallToActionWithAnnotation from "./Landing";

const App = ()=>{
    return <ChakraProvider theme={theme}>

            <Flex p={2} w={"100%"} flexDir={"column"} justifyContent={"flex-start"} alignItems={"flex-start"}>
                <CallToActionWithAnnotation/>

            </Flex>
    </ChakraProvider>
}


export default App;